html {
    height: -webkit-fill-available;
}

body {
    padding: 0;
    margin: 0;
    min-height: 100%;
}

@supports (-webkit-touch-callout: none) {
    body {
        /* The hack for Safari */
        min-height: -webkit-fill-available;
    }
}

body {
    background: url('/assets/images/steel.jpg') center center no-repeat;;
    background-color: #11204B;
    background-size: cover;
    position: relative;
    color: white;
    font-family: 'Poiret One';;
    text-align: center;
}

h2, h3 {
    margin: 0;
    text-shadow: 0 0 2px black;
    font-weight: bold;
    background-color: rgba(0, 0, 0, .8);
}

h3 {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
}

p, ol, ul {
    text-align: left;
    text-shadow: 0 0 2px black;
    font-size: 1.2rem;
    font-family: 'Poiret One';
}

ol {
    list-style-type: upper-alpha;
}

.qr {
    display: block;
    margin: 20px auto 30px;
    width: 50px;
    height: 50px;
    background: transparent url(/assets/images/qr.png) no-repeat center center;
    background-size: contain;

    &.red {
        filter: invert(13%) sepia(77%) saturate(6121%) hue-rotate(358deg) brightness(101%) contrast(112%);
    }
    &.yellow {
        filter: invert(89%) sepia(84%) saturate(781%) hue-rotate(354deg) brightness(104%) contrast(107%);
    }
    &.blue {
        filter: invert(8%) sepia(86%) saturate(7186%) hue-rotate(246deg) brightness(109%) contrast(147%);
    }
    &.green {
        filter: invert(24%) sepia(93%) saturate(1849%) hue-rotate(104deg) brightness(97%) contrast(108%);
    }
    &.gray {
        filter: invert(66%) sepia(97%) saturate(0%) hue-rotate(253deg) brightness(99%) contrast(104%);
    }
    &.pink {
        filter: invert(75%) sepia(38%) saturate(5881%) hue-rotate(310deg) brightness(148%) contrast(121%);
    }
    &.purple {
        filter: invert(13%) sepia(60%) saturate(4532%) hue-rotate(289deg) brightness(91%) contrast(117%);
    }
    &.brown {
        filter: invert(25%) sepia(23%) saturate(6624%) hue-rotate(345deg) brightness(79%) contrast(83%);
    }
    &.orange {
        filter: invert(78%) sepia(76%) saturate(3709%) hue-rotate(360deg) brightness(100%) contrast(110%);
    }
    &.cyan {
        filter: invert(100%) sepia(38%) saturate(7498%) hue-rotate(100deg) brightness(98%) contrast(107%);
    }
    &.lightgreen {
        filter: invert(86%) sepia(20%) saturate(877%) hue-rotate(63deg) brightness(98%) contrast(91%);
    }
}

.answer-panel {
    position: absolute;
    margin-top: 0;
    top: calc(100% + 20px);
    left: -20px;
    right: -20px;
    height: 220px;
    text-align: left;
    padding: 20px 40px;
    transition: margin-top .5s;

    &.opened {
        margin-top: -220px;
    }

    h2 {
        margin: 0;
    }

    &.panel-error {
        background-color: #c50000;
    }
}

zxing-scanner {
    aspect-ratio: 1;
    margin: 20px auto;
    display: block;
    background: rgba(255, 255, 255, .7) url(/assets/images/fingerprint.png) no-repeat center center;
    background-size: 75%;
    width: 75%;
    min-width: 220px;
    animation: .5s ease-in 0s infinite alternate fingerprint;

    &::ng-deep video {
        height: 100%;
        object-fit: contain;
    }
}

img {
    max-width: 100%;
}